<template>
  <div>
    <a-modal
      :visible="visible.modalEditGolongan"
      :confirm-loading="loading.confirm"
      @ok="handleOkEditGolongan"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Perbaharui"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> KONFIRMASI EDIT
        GOLONGAN
      </h3>
      <p>
        Apakah anda yakin mau memperbarui nama golongan ini? Setelah diperbarui,
        data lama golongan ini akan digantikan dengan data yang baru.
      </p>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="mr-3">
        <a-input
          v-model="golonganSearch"
          @keyup="searchGolongan"
          size="large"
          style="width: 320px"
          :placeholder="`Cari Nama / Gaji Pokok Golongan...`"
        />
      </div>
      <div class="ml-auto">
        <template v-if="!edit.golongan">
          <a-button size="large" @click.prevent="toEditGolongan" icon="edit"
            >Ubah</a-button
          >
        </template>
        <template v-else>
          <a-button
            @click.prevent="cancelEdit"
            :loading="loading.cancel"
            type="danger"
            size="large"
            icon="close"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >Batal</a-button
          >
          <a-button
            size="large"
            @click.prevent="saveEdit"
            icon="save"
            style="background-color: #41b883; color: white"
            >Simpan</a-button
          >
        </template>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="golonganColumns"
        :data-source="golonganDataTable"
        :loading="loading.table"
        :pagination="pagination"
        @change="handleTableChangeGolongan"
        bordered
      >
        <div
          v-if="edit.golongan"
          slot="gapok"
          slot-scope="text, record"
        >
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) =>
                handleEditGolonganChange(e.target.value, record.key, 'gapok')
            "
          />
        </div>
        <div v-else slot="gapok" slot-scope="text">
          <span>{{ thousandPrice(text) }}</span>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import thousandPrice from '@/helpers/thousandPrice'

const golonganColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    scopedSlots: { customRender: 'no' },
    width: '10%',
    align: 'center',
    sorter: true,
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    align: 'center',
    sorter: true,
    width: '40%',
  },
  {
    title: 'Gaji Pokok',
    dataIndex: 'gapok',
    key: 'gapok',
    scopedSlots: { customRender: 'gapok' },
    align: 'center',
    sorter: true,
    width: '50%',
  },
]

export default {
  name: 'master-golongan',
  data() {
    return {
      thousandPrice,
      golonganColumns,
      golonganDataTable: [],
      savedGolonganData: [],
      visible: {
        modalAddGolongan: false,
        modalEditGolongan: false,
      },
      loading: {
        table: false,
        add: false,
        delete: false,
        cancel: false,
        confirm: false,
      },
      pagination: {},
      golonganPage: 'all',
      golonganSearch: '',
      golonganOrder: '',
      golonganSortBy: '',
      golonganName: null,
      golonganSalary: null,
      edit: {
        golongan: false,
      },
    }
  },
  methods: {
    handleEditGolonganChange(value, key, column) {
      const newData = [...this.golonganDataTable]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedGolonganData = newData
      }
    },
    handleTableChangeGolongan(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'nama') {
        sortBy = 'nama'
      } else if (sorter.field === 'gapok') {
        sortBy = 'gaji_pokok'
      } else {
        sortBy = ''
      }
      this.fetchDataGolongan({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    handleAllLoading(boolean) {
      this.loading.table = boolean
      this.loading.add = boolean
      this.loading.delete = boolean
      this.loading.cancel = boolean
    },
    showModalAddGolongan(boolean) {
      this.visible.modalAddGolongan = boolean
    },
    searchGolongan() {
      if (this.golonganSearch.length > 2) {
        this.fetchDataGolongan({
          search: this.golonganSearch,
        })
      } else {
        this.fetchDataGolongan({
          search: this.golonganSearch,
        })
      }
    },
    fetchDataGolongan(
      params = {
        order: this.golonganOrder,
        sortBy: this.golonganSortBy,
        search: this.golonganSearch,
        page: this.golonganPage,
      },
    ) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminHR/GET_GOLONGAN_BY_SITUATION', {
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          page: params.page,
        })
        .then((res) => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          let no = 1
          this.golonganDataTable = res.data.map((row) => {
            return {
              key: row.id,
              no: no++,
              nama: row.nama,
              gapok: row.gaji_pokok,
            }
          })
          this.savedGolonganData = this.golonganDataTable
          this.handleAllLoading(false)
        })
    },
    handleCancel() {
      this.visible.modalAddGolongan = false
      this.visible.modalEditGolongan = false
    },
    handleAddGolonganChange(value, column) {
      if (column === 'nama') {
        this.golonganName = value
      }
      if (column === 'gapok') {
        this.golonganSalary = value
      }
    },
    handleCreateGolongan(value) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminHR/CREATE_GOLONGAN', {
          data: {
            nama: this.golonganName,
            gapok: this.golonganSalary,
          },
        })
        .then(
          (
            params = {
              page: 'all',
              order: 'ASC',
              sortBy: '',
              search: '',
            },
          ) => {
            return this.$store
              .dispatch('adminHR/GET_GOLONGAN_BY_SITUATION', {
                order: params.order,
                sortBy: params.sortBy,
                search: params.search,
                page: params.page,
              })
              .then((res) => {
                // console.log('res', res)
                const pagination = { ...this.pagination }
                pagination.total = res.total
                this.pagination = pagination
                let no = 1
                this.golonganDataTable = res.data.map((row) => {
                  return {
                    key: row.id,
                    no: no++,
                    nama: row.nama,
                    gapok: row.gaji_pokok,
                  }
                })
                this.savedGolonganData = this.golonganDataTable
                this.handleAllLoading(false)
                this.visible.modalAddGolongan = false
                setTimeout(() => {
                  this.$notification.success({
                    message: 'Sukses',
                    description: `Berhasil menambah golongan baru ${this.golonganName}`,
                  })
                })
              })
          },
        )
    },
    deleteGolongan(value, key) {
      this.handleAllLoading(true)
      this.$confirm({
        title: 'Peringatan!!',
        content: (
          <div>
            Apakah anda yakin ingin menghapus golongan ini? Setelah menghapus,
            data golongan ini akan hilang!
          </div>
        ),
        onOk: () => {
          this.$store
            .dispatch('adminHR/DELETE_GOLONGAN', {
              id: key,
            })
            .then((res) => {
              this.handleAllLoading(false)
              if (res === 200) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Golongan berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Golongan tidak berhasil dihapus',
                })
              }
              this.fetchDataGolongan()
            })
        },
        onCancel: () => {
          this.handleAllLoading(false)
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    toEditGolongan() {
      this.edit.golongan = true
    },
    saveEdit() {
      this.visible.modalEditGolongan = true
    },
    cancelEdit() {
      this.handleAllLoading(true)
      this.fetchDataGolongan()
      setTimeout(() => {
        this.edit.golongan = false
        this.handleAllLoading(false)
      })
    },
    validateGolonganDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].gapok === '' ||
          data[i].gapok === '-' ||
          data[i].gapok === null
        ) {
          data[i].gapok = 0
        } else if (isNaN(Number(data[i].gapok))) {
          isInvalid = true
          message = `Maaf gaji pokok no. ${i + 1}, harus angka!`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkEditGolongan() {
      const newData = [...this.savedGolonganData]
      if (newData.length) {
        const validation = this.validateGolonganDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.visible.modalEditGolongan = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              nama: row.nama,
              gaji_pokok: row.gapok,
            }
          })
          // console.log('Berhasil', target)
          this.confirmLoading = true
          this.$store
            .dispatch('adminHR/UPDATE_GOLONGAN', {
              data: target,
            })
            .then((res) => {
              setTimeout(() => {
                this.fetchDataGolongan()
                this.edit.golongan = false
                this.visible.modalEditGolongan = false
                this.handleAllLoading(false)
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Sukses',
                    description: 'Golongan berhasil diperbarui',
                  })
                } else {
                  this.$notification.error(
                    {
                      message: 'Gagal',
                      description: 'Golongan tidak berhasil diperbarui',
                    },
                    1000,
                  )
                }
              })
            })
        }
      } else {
        this.edit.golongan = false
        this.visible.modalEditGolongan = false
        this.handleAllLoading(false)
        setTimeout(() => {
          this.$notification.error({
            message: 'Gagal',
            description: 'Golongan tidak berhasil diperbarui',
          })
        })
      }
    },
  },
  mounted() {
    this.fetchDataGolongan()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="css">
.blackBorder {
  border: 1px solid black;
}
</style>
